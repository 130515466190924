import React, { useLayoutEffect, useEffect, useState } from "react"
import ExpressForm from "./ExpressForm";
import backgroundImage from './footerImage.svg'
// import logo from './v2.png'
import logo from './carmatars.png'
import '../styles/expressFormDynamic.css'
// import { useMediaQuery } from 'react-responsive'

import TrustpilotDesktop from "./trustpilotWidgetDesktop";
import TrustpilotMobile from "./trustpilotWidgetMobile";


function subText() {

  if (process.env.REACT_APP_PARTNER_USP_SUBTEXT === '') {
    return null
  } else {
    return (
      <h3 className="text-md font-semibold px-6 pb-6" style={{ color: process.env.REACT_APP_PARTNER_USP_SUBTEXT_COLOUR }}>{process.env.REACT_APP_PARTNER_USP_SUBTEXT}</h3>
    )
  }
}



// ** Media Queries to decide how to show TP Widget

// const useDesktopMediaQuery = () =>
//   useMediaQuery({ query: "(min-width: 1280px)" })

// const useTabletAndBelowMediaQuery = () =>
//   useMediaQuery({ query: "(max-width: 1279px)" })

// interface MediaProps {
//   children: any;
// }

// const Desktop = ({ children }: MediaProps): JSX.Element | null => {
//   const isDesktop = useDesktopMediaQuery()
//   if (isDesktop) {

//     return children
//   }
//   return null

// }

// const TabletAndBelow = ({ children }: MediaProps): JSX.Element | null => {
//   const isTabletAndBelow = useTabletAndBelowMediaQuery()
//   if (isTabletAndBelow) {

//     return children
//   }
//   return null
// }



// class Layout extends React.Component {
const Layout = () => {


  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [renderMobileTP, setRenderMobileTP] = useState<boolean>(false);



  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 450) {
        setRenderMobileTP(true);
      } else {
        setRenderMobileTP(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 450) {
      setRenderMobileTP(true);
    } else {
      setRenderMobileTP(false);
    }
  }, [windowWidth]);

  const scrollToForm = () => {
    const form = document.getElementById('formStart');
    if (form) {
      form.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('Could not find form element');
    }
  };

  console.log(process.env.REACT_APP_PARTNER_NAME, typeof process.env.REACT_APP_PARTNER_NAME)



  return (
    <>
      <div className="md:h-screen w-full pb-6" style={{ backgroundColor: '#FAFAFA', backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>
        <div className="md:flex w-full gap-6 justify-center mx-auto md:px-12" style={{ maxWidth: '1440px' }}>
          <div className="flex flex-auto flex-col xs:w-full lg:w-2/3 justify-start items-start mx-6 md:mr-3" >
            <div className="flex flex-row w-full items-start justify-start mt-6 mx-auto  ">
              <a href={process.env.REACT_APP_PARTNER_URL?.toString() ?? ''} target="_blank" rel="noopener noreferrer">
                <img src={logo} alt={`${process.env.REACT_APP_PARTNER_NAME} logo`} className=" w-36" />
              </a>

            </div>
            <div className="flex flex-col items-start justify-start bg-partnerBrandPrimary mx-auto  mt-4 w-full rounded-lg shadow-sm">
              <h1 className="text-xl px-6 pt-6 pb-6" style={{ fontWeight: '500', color: process.env.REACT_APP_PARTNER_USP_TEXT_COLOUR }} >{process.env.REACT_APP_PARTNER_USP_TEXT}</h1>
              {subText()}
              <div className="flex flex-row w-full items-start justify-start px-6 pb-6 mx-auto md:hidden ">
                <button onClick={scrollToForm} data-dd-action-name="Click Apply Now on Mobile" className="text-partnerBrandPrimary bg-white font-semibold font-avenir text-sm px-8 py-3 rounded-full">
                  {process.env.REACT_APP_CC_CTA_TEXT_MOBILE}
                </button>
              </div>
            </div>
            <div className="flex flex-col  items-start justify-start bg-white  mt-4 w-full rounded-lg py-4 px-5 shadow-sm" style={{}}>
              <div className="flex flex-row items-start justify-start w-full  border-gray-100" style={{}}>
                <div className="flex flex-col items-start justify-start w-full">
                  <a href="https://www.carclarity.com.au/" target="_blank" rel="noopener noreferrer">
                    <img style={{ height: "29px", width: "104px" }} className="" alt="CarClarity Logo" src="https://widgets.carclarity.com.au/img/CarClarity.2d0241f0.svg" />
                  </a>
                  <div className={`flex flex-col items-start justify-start w-full pb-4 border-b ${process.env.REACT_APP_CC_IS_ASSET_FINANCE === 'true' ? 'lg:trucksAndStars' : process.env.REACT_APP_PARTNER_NAME === "ClearScore-Personal" ? 'lg:moneybagAndStars' : 'lg:carsAndStars'}`}>
                    <h2 className="text-xl text-gray-800 font-semibold mb-4 mt-4 ">{process.env.REACT_APP_CC_BULLETPOINT_CTA}</h2>
                    <ul className="cc-bullet-list" style={{}}>
                      <li className="cc-bullet-list-item">
                        <p className="text-gray-800 text-sm">{process.env.REACT_APP_CC_BULLETPOINT_1}</p>
                      </li>
                      <li className="cc-bullet-list-item">
                        <p className="text-gray-800 text-sm">{process.env.REACT_APP_CC_BULLETPOINT_2}</p>
                      </li>
                      <li className="cc-bullet-list-item">
                        <p className="text-gray-800 text-sm">{process.env.REACT_APP_CC_BULLETPOINT_3}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {renderMobileTP ? <TrustpilotMobile /> : <TrustpilotDesktop />}


            </div>

          </div>
          <div id="express-form" className="flex flex-auto flex-col xs:w-full lg:w-1/3 mx-6 md:ml-3 md:mr-6 py-4 px-5 xs:mb-4 justify-center items-center bg-white mt-4 rounded-lg shadow-sm " >
            {/* <Header /> */}
            {/* {renderForm()} */}
            <ExpressForm />
          </div>
        </div>
      </div>
    </>
  )
}


export default Layout;